<template>
  <div class="card w-100" style="height: 100vh">
    <b-modal
      ref="my-modal"
      hide-footer
      modal-class="modal-warning"
      :no-close-on-backdrop="true"
      ok-only
      ok-variant="secondary"
    >
      <template #modal-header="{}">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5 class="text-warning mb-0">
            <feather-icon icon="AlertTriangleIcon" class="mr-25" />Warning
          </h5>
          <b-button
            @click="logout"
            size="sm"
            variant="outline-primary"
            class="m-25"
          >
            Logout</b-button
          >
        </div>
      </template>
      <div
        class="d-flex flex-column align-items-center justify-content-start mb-1"
      >
        <h5>Please choose an organization to access the selected page.</h5>
        <v-select
          v-model="root_org_id"
          label="text"
          :options="organizations"
          autocomplete
          style="width: 400px"
          :reduce="(org_id) => org_id.value"
          class="mt-50"
          placeholder="--Search Organization--"
          @input="orgaizationSwitcher()"
          :clearable="false"
        />
      </div>
    </b-modal>
    <div
      class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
    >
      <b-img style="max-width: 160px" src="/logo.png" alt="logo" />
      <p class="mt-1 text-center">
        Verifying your credentials. Please wait while we authenticate your
        account
      </p>
      <b-spinner variant="primary" class="mt-25" />
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BImg,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BModal,
  BTabs,
  BTab,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BSpinner,
    BImg,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BTabs,
    BTab,
    BAvatar,
    vSelect,
  },

  data() {
    return {
      workspaces: [],
      org_id: this.$store.state.app.org_id,
      workspaces: [],
      organizations: [],
    };
  },

  computed: {
    root_org_id: {
      get() {
        return this.$store.state.app.org_id;
      },
      set(org_id) {
        if (
          this.$cookies.get("redirectPath") &&
          this.$cookies.get("redirectPath") != "null"
        ) {
          this.$store.dispatch("app/getOrg", org_id);
          const path = this.$cookies.get("redirectPath");
          this.$cookies.set(
            "redirectPath",
            null,
            null,
            null,
            process.env.VUE_APP_DOMAIN
          );
          this.$router
            .push(`/${path}`)
            .then((res) => {
              this.$store.dispatch("app/currenUser").then((response) => {
                this.$store.dispatch("app/getOrgRefresh", org_id);
              });
              this.workspaces.map((item) => {
                if (item.tenant_uid === org_id) {
                  this.$store.commit("app/set_currentWorkspace", item);
                  const currentmodule = [];
                  item.enabled_modules.map((mod) => {
                    currentmodule.push(mod.name);
                  });
                  this.$store.commit("app/set_currentModules", currentmodule);
                }
              });
            })
            .catch((err) => {
              console.log(
                "Error during Redirecting to redirectPath",
                err,
                path
              );
            });
        } else {
          this.$store.dispatch("app/getOrg", org_id);
          this.$store.dispatch("app/currenUser").then((response) => {
            this.$store.dispatch("app/getOrgRefresh", org_id);
            this.$router.push("/");
          });

          this.workspaces.map((item) => {
            if (item.tenant_uid === org_id) {
              this.$store.commit("app/set_currentWorkspace", item);
              const currentmodule = [];
              item.enabled_modules.map((mod) => {
                currentmodule.push(mod.name);
              });
              this.$store.commit("app/set_currentModules", currentmodule);
            }
          });
        }
      },
    },
  },

  // mounted() {
  //   this.load();
  // },

  methods: {
    load() {
      this.$store.commit("app/auth_success", $cookies.get("token"));
      this.$store.dispatch("app/currenUser").then((response) => {
        this.workspaces = this.$store.state.app.workspaces;
        if (
          this.$store.state.app.org_id &&
          this.$store.state.app.org_id != null
        ) {
          this.$store.dispatch(
            "app/getOrgRefresh",
            this.$store.state.app.org_id
          );
          this.$store.dispatch("notification/getAllNotifications", {});
        }
        // this.$store.dispatch("app/checkIfUserChanged");
        if (this.$store.state.app.workspaces.length === 0) {
          this.$router.push({ name: "noworkspace" });
        } else if (
          this.$store.state.app.workspaces.length > 0 &&
          this.$cookies.get("redirectPath") &&
          this.$cookies.get("redirectPath") != "null"
        ) {
          this.setOrganizationOptionsFromWorkspaces();
          this.checkIfOrganizationSelected();
        } else {
          this.$router.push({ name: "home" });
        }
      });
    },

    logout: function () {
      this.$store.dispatch("app/logout").then(() => {
        this.$router.push("/login");
      });
    },

    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    orgaizationSwitcher() {
      this.$store
        .dispatch("notification/getAllNotifications", {})
        .then((res) => {});
    },

    checkIfOrganizationSelected() {
      if (
        !this.$store.state.app.org_id ||
        this.$store.state.app.org_id === null
      ) {
        if (
          this.$store.state.app.workspaces &&
          this.$store.state.app.workspaces.length == 1
        ) {
          // console.log('workspacesss',this.$store.state.app.workspaces[0])
          this.root_org_id = this.$store.state.app.workspaces[0].tenant_uid;
        } else {
          this.showModal();
        }
      }
    },

    getOrganisation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/get-all-org/",
      };
      var self = this;
      return this.$http(options).then((res) => {
        res.data.map(function (value, key) {
          let org = {
            // value: res.data[key].org_id,
            value: res.data[key].sso_ref_id,
            text: res.data[key].org_name,
          };
          self.org_id = res.data[0].org_id;
          self.organizations.push(org);
        });
      });
    },

    setOrganizationOptionsFromWorkspaces() {
      console.log("Workspaces", this.workspaces);
      var self = this;
      this.workspaces.forEach(function (value, key) {
        let org = {
          value: self.workspaces[key].tenant_uid,
          text: self.workspaces[key].name,
        };
        self.organizations.push(org);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
@import "vue-select/src/scss/vue-select.scss";
[dir] .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px !important;
  padding-top: 4px !important;
  // background-color: white;
}
[dir="ltr"] .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #6e6b7b;
}

.dark-layout .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #b4b7bd;
}
.dark-layout .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px;
  padding-top: 4px;
  // background-color: #283046;
}
</style>
